<template>
    <div>

        <div class="page-title-large">
            <h1 class="section-title">Privacy Policy</h1>
        </div>        

        <section class="text-page">
                <div class="container-lg">
                    <div class="text-page-inner">

                        <h1 id="-inprivacy-policy-"><strong>inPrivacy Policy</strong></h1>
<h3 id="-introduction-"><strong>Introduction</strong></h3>
<p>This Privacy Policy (&quot;Policy&quot;) explains how <strong>Sabasi</strong> (&quot;we&quot;, &quot;us&quot;, or &quot;our&quot;) collects, uses, maintains, and discloses your personal information when you use:</p>
<ul>
<li>The <strong>Sabasi v2 mobile app</strong> (&quot;App&quot;),  </li>
<li>The Sabasi website and any related subdomains,  </li>
<li>Any other online services provided by Sabasi.</li>
</ul>
<p>Sabasi offers a data collection platform designed for organizations, individuals, and researchers to manage and conduct surveys efficiently. This Policy applies to both guests and registered users of the Sabasi platform (&quot;Users&quot;, &quot;You&quot;, &quot;you&quot;). Through the Sabasi App, users can collect various types of data, including personal data, from survey participants. The personal data collected through surveys is processed on behalf of the organization or individual creating the survey (&quot;Survey Owners&quot;). Therefore, Sabasi acts as a data processor for the information collected from survey participants and a data controller for the information we collect from registered users.</p>
<p>By using the Sabasi platform, you agree to the collection and use of your information in accordance with this Policy. If you do not agree with any part of this Policy, please do not use our services.</p>
<p>This Policy may be updated periodically. You are advised to review this Privacy Policy regularly for any changes. If changes are made, we will notify you by updating the &quot;Effective Date&quot; at the top of this page and, if necessary, through a prominent notice within the App or by email. Your continued use of the Sabasi platform after changes are posted constitutes your acceptance of those changes.</p>
<h3 id="-1-information-we-collect-how-we-collect-it-and-data-retention-"><strong>1. Information We Collect, How We Collect It, and Data Retention</strong></h3>
<p>When you engage with our services, we collect your personal information, which is information that identifies (whether directly or indirectly) a particular individual. Except as otherwise indicated, the personal information we collect is necessary to carry out the requested action. If you do not provide us with your personal information, we would not be able to perform the requested service.</p>
<h4 id="-a-register-for-an-account-"><strong>a) Register for an Account</strong></h4>
<p>When you register for an account, we will collect your identifiers, such as:</p>
<ul>
<li><strong>Name</strong>  </li>
<li><strong>Email address</strong>  </li>
<li><strong>Phone number</strong>  </li>
<li><strong>Organization name</strong> (if applicable)  </li>
<li><strong>Country of residence</strong></li>
</ul>
<p>We use this information to create your account, allow access to the Sabasi platform, and communicate with you about the services. We may also collect additional data, such as the organization or industry you are associated with. If you contact us for support or assistance, we may collect additional details to help resolve your issue.</p>
<p>We retain your personal information for as long as your account is active and for no more than two years after it has become inactive. If you request that your account be deleted, we will remove your personal information within 30 days.</p>
<h4 id="-b-fill-out-a-contact-form-"><strong>b) Fill Out a Contact Form</strong></h4>
<p>When you fill out a contact form on the Sabasi website or through the App, we collect:</p>
<ul>
<li><strong>Name</strong>  </li>
<li><strong>Email address</strong>  </li>
<li><strong>Phone number</strong> (optional)  </li>
<li><strong>Organization name</strong> (if applicable)  </li>
<li>Any additional information you include in your message.</li>
</ul>
<p>We use this information to process your inquiry and provide assistance. We retain your personal information for up to two years after resolving your inquiry, or longer if your account remains active.</p>
<h4 id="-c-participate-in-in-app-surveys-"><strong>c) Participate in In-App Surveys</strong></h4>
<p>When you participate in surveys or contribute responses through the Sabasi platform, we collect:</p>
<ul>
<li><strong>Survey responses</strong>  </li>
<li><strong>Media files</strong> (such as images) submitted through the surveys  </li>
<li><strong>Geolocation data</strong> (if you consent to location sharing)</li>
</ul>
<p>Survey responses may include personal data if you voluntarily provide it in the course of completing the survey. The data collected in surveys is stored and processed on behalf of the organization or entity that created the survey.</p>
<p>We retain survey responses and associated data for as long as necessary to fulfill the purpose of the survey, or for as long as required by the organization running the survey.</p>
<h4 id="-d-use-of-cookies-and-tracking-technologies-"><strong>d) Use of Cookies and Tracking Technologies</strong></h4>
<p>We collect information automatically through cookies and similar tracking technologies when you interact with our website or App. This may include:</p>
<ul>
<li><strong>IP address</strong>  </li>
<li><strong>Browser type</strong>  </li>
<li><strong>Operating system</strong>  </li>
<li><strong>Device type and unique identifiers</strong>  </li>
<li><strong>Time spent on pages</strong>  </li>
<li><strong>Clicks and navigation data</strong></li>
</ul>
<p>We use this information to analyze usage patterns and improve the performance and user experience of the platform. You can manage cookie preferences through your browser settings. We retain this data for up to two years after your last interaction with our platform.</p>
<h4 id="-e-communication-and-customer-support-"><strong>e) Communication and Customer Support</strong></h4>
<p>When you communicate with us via email or through the App&#39;s built-in support feature (powered by <strong>Smartsupp</strong>), we collect:</p>
<ul>
<li><strong>Email address</strong>  </li>
<li><strong>Name</strong>  </li>
<li><strong>Message content</strong>  </li>
<li>Any attachments or additional information you provide.</li>
</ul>
<p>We use this data to respond to inquiries, provide support, and improve the service. We retain these communications for up to two years unless further retention is required for operational, legal, or regulatory reasons.</p>
<h4 id="-f-data-retention-"><strong>f) Data Retention</strong></h4>
<p>We retain your personal information only for as long as is necessary to fulfill the purposes for which it was collected, comply with legal obligations, resolve disputes, and enforce our agreements. Upon the deletion of your account or upon your request, we will securely erase your personal data within 30 days, unless legal obligations require us to retain it for a longer period.</p>
<h3 id="-2-how-we-share-your-personal-information-"><strong>2. How We Share Your Personal Information</strong></h3>
<p>We do not sell or rent your personal information to third parties. However, we may share your personal information in the following circumstances:</p>
<h4 id="-a-with-service-providers-"><strong>a) With Service Providers</strong></h4>
<p>We may share your personal information with third-party service providers who help us operate and improve our platform. These service providers include:</p>
<ul>
<li><strong>Cloud storage providers</strong>: To securely store your data.  </li>
<li><strong>Customer support platforms</strong> (e.g., <strong>Smartsupp</strong>): To assist with resolving inquiries and improving user support.</li>
</ul>
<p>These third parties only have access to your personal information to perform specific tasks on our behalf and are contractually obligated not to disclose or use it for any other purpose.</p>
<h4 id="-b-with-survey-owners-"><strong>b) With Survey Owners</strong></h4>
<p>When you participate in a survey, your personal data and survey responses are shared with the organization or individual (&quot;Survey Owners&quot;) who created the survey. Sabasi processes this data on behalf of the Survey Owners, and they are responsible for how your data is used and managed. If you have concerns about the use of your data in a survey, please contact the Survey Owner directly.</p>
<h4 id="-c-for-legal-obligations-"><strong>c) For Legal Obligations</strong></h4>
<p>We may disclose your personal information if required to do so by law or in response to valid legal processes, such as court orders, subpoenas, or government regulations. We may also share your information when necessary to:</p>
<ul>
<li>Comply with legal obligations,  </li>
<li>Protect and defend the rights or property of Sabasi,  </li>
<li>Prevent illegal activity, fraud, or harm to the platform, its users, or the public.</li>
</ul>
<h4 id="-d-business-transfers-"><strong>d) Business Transfers</strong></h4>
<p>In the event of a merger, acquisition, restructuring, or sale of Sabasi’s assets, your personal information may be transferred as part of the transaction. We will notify you via email or a prominent notice on our website or within the App if such a transfer occurs and will outline your options regarding your personal information.</p>
<h4 id="-e-with-your-consent-"><strong>e) With Your Consent</strong></h4>
<p>In any circumstances not covered by this Privacy Policy, we will ask for your explicit consent before sharing your personal information with third parties. You can withdraw your consent at any time by contacting us.</p>
<h3 id="-3-your-options-for-managing-your-information-"><strong>3. Your Options for Managing Your Information</strong></h3>
<p>You have the following choices regarding the personal information we collect and how it is used:</p>
<h4 id="-a-access-and-update-your-information-"><strong>a) Access and Update Your Information</strong></h4>
<p>You can access and update your personal information through your account settings on the Sabasi platform. If any of the information you’ve provided is incorrect, you can correct it within the platform, or you may contact us directly for assistance.</p>
<h4 id="-b-request-to-delete-your-data-"><strong>b) Request to Delete Your Data</strong></h4>
<p>You have the right to request the deletion of your personal information. If you wish to delete your account or any personal data we hold about you, please contact us at help@sabasi.mobi. We will process your request within 30 days unless we are required by law or legal obligations to retain the data for a longer period.</p>
<h4 id="-c-opt-out-of-marketing-communications-"><strong>c) Opt-Out of Marketing Communications</strong></h4>
<p>If you have subscribed to receive marketing communications from us, you may opt out at any time by clicking the &quot;unsubscribe&quot; link included in our emails. Even if you opt out of marketing emails, we may still send you necessary communications related to your account or services you’ve requested (e.g., system notifications, updates about your surveys).</p>
<h4 id="-d-manage-cookies-and-tracking-technologies-"><strong>d) Manage Cookies and Tracking Technologies</strong></h4>
<p>You can manage your cookie preferences through your browser settings. Most web browsers allow you to:</p>
<ul>
<li>Block cookies,  </li>
<li>Delete existing cookies, or  </li>
<li>Receive a notification before cookies are stored.</li>
</ul>
<p>Please note that disabling cookies may affect your ability to use some features of the Sabasi platform. For detailed information on how to manage and delete cookies.</p>
<h4 id="-e-control-location-data-"><strong>e) Control Location Data</strong></h4>
<p>If you have given the Sabasi app access to your location data, you can change this preference at any time through your device’s settings. Disabling location access will not prevent you from using the app but may limit certain features that rely on location data.</p>
<h4 id="-f-limit-data-sharing-with-survey-owners-"><strong>f) Limit Data Sharing with Survey Owners</strong></h4>
<p>If you participate in surveys, you are providing your data to the organization or individual that created the survey. You can choose not to participate in surveys if you do not wish to share your data with the Survey Owners. For more details on how your data will be used, refer to the privacy policy of the organization conducting the survey.</p>
<h4 id="-g-withdraw-consent-"><strong>g) Withdraw Consent</strong></h4>
<p>Where we rely on your consent to process your personal data, you have the right to withdraw that consent at any time. If you wish to withdraw consent, please contact us at help@sabasi.mobi. However, withdrawing your consent may affect your use of certain features of the platform.</p>
<h3 id="-4-your-rights-regarding-your-information-"><strong>4. Your Rights Regarding Your Information</strong></h3>
<p>As a user of the Sabasi platform, you have several rights concerning the personal information we collect and process about you. These rights allow you to control how your data is used and help protect your privacy. You can exercise these rights by contacting us at help@sabasi.mobi.</p>
<h4 id="-a-right-to-access-"><strong>a) Right to Access</strong></h4>
<p>You have the right to request a copy of the personal information we hold about you. We will provide you with a summary of your personal data and explain how we use it. You can request this information by contacting us at any time.</p>
<h4 id="-b-right-to-rectification-"><strong>b) Right to Rectification</strong></h4>
<p>If the personal information we have about you is inaccurate or incomplete, you have the right to request that we correct or update it. You can make these changes directly through your account settings, or you can reach out to us for assistance.</p>
<h4 id="-c-right-to-erasure-right-to-be-forgotten-"><strong>c) Right to Erasure (Right to Be Forgotten)</strong></h4>
<p>You can request that we delete your personal information when it is no longer necessary for the purposes for which it was collected. We will delete your data unless we are required to keep it for legal or regulatory reasons.</p>
<h4 id="-d-right-to-restrict-processing-"><strong>d) Right to Restrict Processing</strong></h4>
<p>In certain situations, you have the right to request that we restrict the processing of your personal data. This means we will only store your data and not use it for any further processing unless you give us consent or it is necessary for legal reasons.</p>
<h4 id="-e-right-to-data-portability-"><strong>e) Right to Data Portability</strong></h4>
<p>As a registered user, you have the right to request a copy of the personal data you have provided to Sabasi in a structured, commonly used, and machine-readable format. You may also request that we transfer your data to another service provider, where technically feasible.</p>
<p><strong>For Survey Participants</strong>: The right to data portability also applies to survey participants, but since Sabasi processes survey responses on behalf of the Survey Owner, you will need to submit your request to the Survey Owner. They are responsible for responding to requests for data portability related to the survey data they control.</p>
<h4 id="-f-right-to-object-"><strong>f) Right to Object</strong></h4>
<p>As a registered user of Sabasi, you have the right to object to certain processing activities related to your personal data. This includes:</p>
<ul>
<li><strong>Direct marketing</strong>: You may object to receiving marketing communications from Sabasi, and we will stop processing your data for this purpose upon your request.  </li>
<li><strong>Processing based on legitimate interests</strong>: If we process your personal data based on our legitimate interests, you have the right to object to this processing. We will review your objection and, unless we have compelling legal reasons to continue processing, we will stop.</li>
</ul>
<p><strong>For Survey Participants</strong>: If you are a survey participant, Sabasi processes your data on behalf of the <strong>Survey Owner</strong>. In this case, the right to object must be exercised by contacting the Survey Owner directly, as they are the <strong>data controller</strong>responsible for determining how your data is used. Sabasi cannot directly respond to such requests from survey participants.</p>
<h4 id="-g-right-to-withdraw-consent-"><strong>g) Right to Withdraw Consent</strong></h4>
<p>If we are processing your personal data based on your consent, you have the right to withdraw that consent at any time. This will not affect the lawfulness of any processing carried out before you withdraw consent.</p>
<h4 id="-h-right-to-lodge-a-complaint-"><strong>h) Right to Lodge a Complaint</strong></h4>
<p>If you believe that your rights under this Privacy Policy or applicable data protection laws have been violated, you have the right to lodge a complaint with a data protection authority. We encourage you to contact us first so we can address your concerns.</p>
<h4 id="-how-to-exercise-your-rights-"><strong>How to Exercise Your Rights</strong></h4>
<ul>
<li><strong>Registered Users</strong>: If you wish to object to data processing or request data portability, you can contact Sabasi directly at <a href="mailto:help@sabasi.mobi">help@sabasi.mobi</a> . We will respond to your request within <strong>30 days</strong>, unless there are legal grounds for extending this period.  </li>
<li><strong>Survey Participants</strong>: To exercise your right to object or request data portability, please contact the Survey Owner who collected your data. If you need assistance in contacting the Survey Owner, you may reach out to Sabasi at <a href="mailto:help@sabasi.mobi">help@sabasi.mobi</a>, and we will help facilitate communication with the Survey Owner.</li>
</ul>
<h3 id="-5-children-s-privacy-"><strong>5. Children’s Privacy</strong></h3>
<p>Sabasi is committed to protecting the privacy of children and complying with applicable child privacy laws, including the <strong>Children’s Online Privacy Protection Act (COPPA)</strong> in the United States and the <strong>General Data Protection Regulation (GDPR)</strong> in the European Union. Our platform is not intended for use by individuals under the age of 13, and we do not knowingly collect personal information from children under this age.</p>
<h4 id="-1-age-verification-"><strong>1. Age Verification</strong></h4>
<p>We take steps to verify the age of users during account registration to ensure compliance with child privacy laws. When a user registers for a Sabasi account, we ask them to confirm that they are at least 13 years old (or 16, depending on the applicable jurisdiction). If we have reason to believe that a user is under the minimum age, we will take appropriate steps to block or delete their account.</p>
<h4 id="-2-parental-consent-for-minors-"><strong>2. Parental Consent for Minors</strong></h4>
<p>In jurisdictions governed by the <strong>GDPR</strong>, the age threshold for consent may vary. Under the GDPR, the processing of personal data for children under the age of 16 is prohibited without parental consent, although EU Member States may set a lower age limit, with a minimum age of 13. In such cases, we will require verifiable <strong>parental consent</strong> before collecting or processing the personal data of minors.</p>
<p>If you are a parent or guardian and believe that your child has provided us with personal information without your consent, please contact us immediately at <a href="mailto:help@sabasi.mobi">help@sabasi.mobi</a>. Upon receiving such a notification, we will take steps to investigate and, if necessary, delete the child’s personal data from our records.</p>
<h4 id="-3-handling-of-minors-data-"><strong>3. Handling of Minors&#39; Data</strong></h4>
<p>In the event that Sabasi inadvertently collects personal information from a child under the age of 13 (or under 16 in applicable jurisdictions), we will:</p>
<ul>
<li><strong>Delete the data</strong>: We will promptly delete the personal data from our systems upon becoming aware of the situation.  </li>
<li><strong>Notify the parent or guardian</strong>: If we have inadvertently collected a minor’s data, we will make reasonable efforts to contact the child’s parent or guardian to inform them of the situation and take any further actions required.</li>
</ul>
<h4 id="-4-compliance-with-coppa-and-gdpr-"><strong>4. Compliance with COPPA and GDPR</strong></h4>
<p>Sabasi complies with the <strong>Children’s Online Privacy Protection Act (COPPA)</strong> in the United States, which requires parental consent before collecting any personal data from children under the age of 13. Similarly, we comply with the <strong>GDPR</strong>’s strict rules for processing minors&#39; data in the European Union, where parental consent is required for children under 16, unless national laws specify a lower age threshold.</p>
<p>In cases where our services may be used in jurisdictions with additional child privacy regulations, we will take steps to ensure compliance with those regulations and protect the privacy of minors.</p>
<h4 id="-5-prohibited-use-by-children-"><strong>5. Prohibited Use by Children</strong></h4>
<p>Sabasi’s platform is designed for use by organizations and individuals who are over the age of 13. We explicitly prohibit children under 13 from creating accounts or submitting personal data through the platform. If we discover that a child under 13 has registered for an account or submitted data without parental consent, we will take immediate action to remove the data and block access to the platform.</p>
<h3 id="-6-international-jurisdictions-data-transfers-"><strong>6. International Jurisdictions &amp; Data Transfers</strong></h3>
<p>Sabasi operates on a global scale, and the personal data we collect may be transferred to and processed in countries other than your country of residence. This includes countries that may not have data protection laws as stringent as those in your jurisdiction, such as the <strong>European Union (EU)</strong> or <strong>Kenya</strong>. However, we take steps to ensure that your personal information is handled securely and in accordance with international data protection standards.</p>
<h4 id="-1-safeguards-for-international-data-transfers-"><strong>1. Safeguards for International Data Transfers</strong></h4>
<p>When transferring personal data internationally, Sabasi ensures that adequate safeguards are in place to protect the data, as required by the <strong>General Data Protection Regulation (GDPR)</strong>, the <strong>Kenyan Data Protection Act (DPA)</strong>, and other applicable data protection laws.</p>
<p>These safeguards may include:</p>
<ul>
<li><strong>Standard Contractual Clauses (SCCs)</strong>: For transfers from the EU or other jurisdictions that require compliance with the GDPR, Sabasi uses the <strong>Standard Contractual Clauses</strong> approved by the European Commission. These clauses are designed to ensure that the personal data transferred to third countries outside the EU receives adequate protection.  </li>
<li><strong>Adequacy Decisions</strong>: In cases where personal data is transferred to countries with an <strong>adequacy decision</strong> from the European Commission, we rely on these decisions as evidence that the receiving country provides an equivalent level of data protection to that of the EU.  </li>
<li><strong>Binding Corporate Rules (BCRs)</strong>: Where applicable, Sabasi may rely on <strong>Binding Corporate Rules</strong> to ensure compliance with data protection standards when transferring data between entities within the same group or to our partners.  </li>
<li><strong>Data Protection Agreements (DPAs)</strong>: For cross-border transfers, especially to countries without an adequacy decision, Sabasi enters into <strong>Data Protection Agreements</strong> with the parties involved to ensure compliance with legal requirements and to protect personal data during and after the transfer.</li>
</ul>
<h4 id="-2-compliance-with-the-kenyan-data-protection-act-dpa-"><strong>2. Compliance with the Kenyan Data Protection Act (DPA)</strong></h4>
<p>Under the <strong>Kenyan Data Protection Act (DPA)</strong>, Sabasi ensures that any cross-border data transfers meet the requirements for safeguarding personal information. We use contractual clauses or obtain consent from data subjects when required, and we ensure that the third country where the data is being transferred has adequate data protection measures in place.</p>
<p>Where data is transferred from Kenya to other countries, Sabasi takes reasonable steps to ensure that the data is treated in a manner consistent with Kenyan data protection principles, including:</p>
<ul>
<li><strong>Obtaining consent</strong>: In cases where required by the Kenyan DPA, we seek explicit consent from data subjects before transferring their personal information outside of Kenya.  </li>
<li><strong>Ensuring protection</strong>: We work with partners and service providers that have adequate data protection measures in place and, where applicable, enter into contractual agreements to safeguard data during transfers.</li>
</ul>
<h4 id="-3-data-transfers-to-third-party-service-providers-"><strong>3. Data Transfers to Third-Party Service Providers</strong></h4>
<p>Sabasi works with third-party service providers, including cloud hosting providers and data processors, who may be located in other countries. When working with such providers, we ensure that:</p>
<ul>
<li><strong>The service provider is compliant</strong> with relevant data protection laws and has appropriate security and privacy practices in place.  </li>
<li><strong>Data transfer agreements</strong> are signed to ensure that the third-party service provider is bound by the same data protection obligations as Sabasi.</li>
</ul>
<h4 id="-4-user-consent-for-data-transfers-"><strong>4. User Consent for Data Transfers</strong></h4>
<p>In cases where the law requires consent for international data transfers (such as under the Kenyan DPA), we will obtain your explicit consent before transferring your personal information outside your home country. You have the right to withdraw your consent for these transfers at any time by contacting us at <a href="mailto:help@sabasi.mobi">help@sabasi.mobi</a>.</p>
<h4 id="-5-ongoing-review-of-safeguards-"><strong>5. Ongoing Review of Safeguards</strong></h4>
<p>We regularly review and update the safeguards we use for international data transfers to ensure compliance with evolving legal standards and technological advancements. Sabasi is committed to maintaining the highest standards of data protection and ensuring that your personal data is secure, regardless of where it is processed.</p>
<p>If you have any questions or concerns about international data transfers or the safeguards we use, you can contact us at <a href="mailto:help@sabasi.mobi">help@sabasi.mobi</a>.</p>
<h3 id="-7-legal-jurisdictions-and-local-compliance-"><strong>7. Legal Jurisdictions and Local Compliance</strong></h3>
<p>Sabasi is based in <strong>Kenya</strong> and complies with the <strong>Kenyan Data Protection Act (DPA)</strong> and other applicable privacy laws in the jurisdictions where our platform is used. We are committed to safeguarding the personal data of our users in accordance with these legal requirements.</p>
<h4 id="-1-compliance-with-the-kenyan-data-protection-act-dpa-"><strong>1. Compliance with the Kenyan Data Protection Act (DPA)</strong></h4>
<p>Sabasi adheres to the principles and obligations outlined in the <strong>Kenyan Data Protection Act (DPA)</strong>, which governs the collection, processing, and storage of personal data in Kenya. The DPA ensures that personal data is processed lawfully, fairly, and transparently. Sabasi complies with the following key provisions of the DPA:</p>
<ul>
<li><strong>Data Minimization</strong>: We only collect personal data that is necessary for the purposes for which it is processed.  </li>
<li><strong>Lawful Processing</strong>: All data processing activities are carried out with a legal basis, such as user consent, performance of a contract, or compliance with legal obligations.  </li>
<li><strong>Data Subject Rights</strong>: Kenyan users have the right to access, correct, or delete their personal data, as well as the right to object to processing and request data portability. These rights are respected and enforced in accordance with the DPA.  </li>
<li><strong>Cross-Border Data Transfers</strong>: When transferring data outside Kenya, we ensure that adequate safeguards, such as <strong>Standard Contractual Clauses (SCCs)</strong> or <strong>Data Protection Agreements (DPAs)</strong>, are in place to protect the data in accordance with the DPA.</li>
</ul>
<h4 id="-2-compliance-with-gdpr-for-eu-users-"><strong>2. Compliance with GDPR (for EU users)</strong></h4>
<p>For users in the <strong>European Union (EU)</strong>, Sabasi complies with the <strong>General Data Protection Regulation (GDPR)</strong>. This includes:</p>
<ul>
<li><strong>Obtaining consent</strong> before processing personal data,  </li>
<li><strong>Allowing users to exercise their data rights</strong>, such as the right to access, rectify, or delete their data, and the right to data portability,  </li>
<li><strong>Appointing a Data Protection Officer (DPO)</strong>, where required, to oversee compliance with GDPR obligations.</li>
</ul>
<h4 id="-3-compliance-with-other-local-privacy-laws-"><strong>3. Compliance with Other Local Privacy Laws</strong></h4>
<p>In addition to the Kenyan DPA and GDPR, Sabasi is committed to complying with local privacy laws in the other jurisdictions where our platform is used. We review and update our practices regularly to ensure compliance with relevant privacy regulations in the regions where our users are located.</p>
<h4 id="-4-user-rights-under-local-privacy-laws-"><strong>4. User Rights Under Local Privacy Laws</strong></h4>
<p>Users in Kenya and other jurisdictions where Sabasi operates have the following rights under applicable privacy laws:</p>
<ul>
<li><strong>Right to Access</strong>: You can request access to the personal data we hold about you.  </li>
<li><strong>Right to Correction</strong>: You can ask us to correct or update your personal data if it is inaccurate or incomplete.  </li>
<li><strong>Right to Deletion</strong>: You can request that your personal data be deleted, subject to legal requirements for retention.  </li>
<li><strong>Right to Object</strong>: You have the right to object to the processing of your personal data in certain circumstances, such as for direct marketing.  </li>
<li><strong>Right to Data Portability</strong>: You can request that we transfer your personal data to another service provider, where feasible.</li>
</ul>
<p>These rights may vary depending on the specific legal framework that applies to your jurisdiction. If you have questions about your rights or how we comply with local privacy laws, please contact us at <a href="mailto:help@sabasi.mobi">help@sabasi.mobi</a>.</p>
<h4 id="-5-legal-recourse-"><strong>5. Legal Recourse</strong></h4>
<p>If you believe that Sabasi has violated your privacy rights under the <strong>Kenyan DPA</strong>, <strong>GDPR</strong>, or other applicable laws, you may file a complaint with the appropriate regulatory authority. We encourage you to contact us first so that we can resolve any concerns before escalating to regulatory bodies.</p>
<h3 id="-8-we-do-not-track-"><strong>8. We Do Not Track</strong></h3>
<p>Sabasi does not track its users or engage in targeted advertising based on user behavior. We do not collect personal data for tracking purposes, nor do we use tracking mechanisms like cookies to follow users across different websites or online services.</p>
<p>Additionally, we do not respond to <strong>Do Not Track</strong> (DNT) signals or similar mechanisms that allow users to express preferences regarding the collection of their information over time and across third-party websites or online services.</p>
<p>If you have any questions about our privacy practices or how we handle your data, please contact us at <a href="mailto:help@sabasi.mobi">help@sabasi.mobi</a>. </p>
<h3 id="-9-information-security-"><strong>9. Information Security</strong></h3>
<p>At <strong>Sabasi</strong>, we take data security seriously and are committed to protecting your personal information from threats. We constantly monitor and work to improve our security framework to meet industry standards and safeguard the data you entrust to us.</p>
<p>Our approach to data security is based on three key principles: <strong>confidentiality</strong>, <strong>integrity</strong>, and <strong>availability</strong>. We employ administrative, organizational, physical, and technical measures to protect your information both in transit and at rest.</p>
<h4 id="-confidentiality-"><strong>Confidentiality</strong></h4>
<ul>
<li><strong>Physical Access Control</strong>: Our servers are hosted in the AWS cloud using secure facilities. Physical access to these data centers is restricted to authorized personnel only, and access is controlled using biometric authentication, keycards, video surveillance, and other security measures provided by the AWS cloud service provider.  </li>
<li><strong>Electronic Access Control</strong>: All user accounts on the Sabasi platform are protected by password authentication. We encourage users to create strong passwords by providing feedback on password complexity. All user passwords are encrypted before storage using modern cryptographic standards. Plaintext passwords are never stored.  </li>
<li><strong>Data Encryption</strong>:  <ul>
<li><strong>At Rest</strong>: All data stored on our servers, including survey responses and personal information, is encrypted at rest using disk-level encryption.  </li>
<li><strong>In Transit</strong>: Data transmitted between your device and our servers is encrypted using modern encryption protocols such as <strong>SHA-256</strong> with <strong>RSA encryption</strong> to ensure that your data remains secure while being transmitted.  </li>
</ul>
</li>
<li><strong>Internal Access Control</strong>: Only authorized system administrators have access to our servers for maintenance purposes. We enforce strict internal policies, including the use of <strong>two-factor authentication</strong> and <strong>SSH key authentication</strong>, to prevent unauthorized access to the server environment.</li>
</ul>
<h4 id="-integrity-"><strong>Integrity</strong></h4>
<ul>
<li><strong>Data Transfer Control</strong>: We ensure that all data sent to and from our servers is encrypted to protect it from unauthorized access or interception during transmission. Data in transit is protected using strong encryption methods.  </li>
<li><strong>Data Entry Control</strong>: Sabasi provides users with control over who can access and submit data on the platform. Permissions can be set to ensure that only authorized users can enter or modify data.  </li>
<li><strong>Monitoring</strong>: We log access events and user activities for security purposes. Our system administrators regularly review logs to identify any unauthorized access or suspicious activity.</li>
</ul>
<h4 id="-availability-and-resilience-"><strong>Availability and Resilience</strong></h4>
<ul>
<li><strong>Data Backups</strong>: Sabasi performs regular backups of all user data to a remote, secure location. In the event of an unexpected outage, we are able to restore user data from the most recent backup to ensure minimal data loss and service disruption.  </li>
<li><strong>System Redundancy</strong>: Our infrastructure is designed to be resilient, with multiple servers running concurrently to handle user traffic and ensure continuous operation. In the event of localized failures, our system automatically shifts the load to other instances to prevent downtime.  </li>
<li><strong>Firewalls and Access Restrictions</strong>: Firewalls are configured to block unauthorized access to our servers, limiting external connections to only those required for operational purposes. Public traffic is routed through secure load balancers before reaching our internal servers.  </li>
<li><strong>Emergency Response</strong>: Our system administrators are available to respond to critical issues around the clock. We have contingency plans in place to ensure that service is restored quickly in the event of a failure, with staff members distributed across multiple geographic locations for redundancy.</li>
</ul>
<hr>
<p><strong>Security is a top priority at Sabasi</strong>, and we are committed to maintaining the highest standards for data protection. Our infrastructure and processes are continuously updated to adapt to new security challenges and industry best practices. If you have any questions about our security measures, feel free to contact us at <a href="mailto:help@sabasi.mobi">help@sabasi.mobi</a>.</p>
<h3 id="-10-data-retention-"><strong>10. Data Retention</strong></h3>
<p>At Sabasi, we retain personal information only for as long as is necessary to fulfill the purposes for which it was collected, comply with legal obligations, resolve disputes, and enforce our agreements. This section outlines how long we retain different types of data and under what conditions data is deleted.</p>
<h4 id="-a-account-information-"><strong>a) Account Information</strong></h4>
<p>Users have the ability to delete their own account directly from the Sabasi platform. When you delete your account, all personal information associated with the account, including your name, email address, and any other contact details, will be permanently deleted from our systems. Additionally, all surveys, responses, and any data you have created or submitted through the platform will be irreversibly deleted.</p>
<p>Once an account is deleted by the user, we will not retain any of the associated data, and it cannot be recovered. However, it may take up to <strong>30 days</strong> for the deletion to be fully processed across all systems, including backups.</p>
<p>For <strong>inactive accounts</strong>:</p>
<ul>
<li>Accounts with <strong>no surveys or responses</strong> will be automatically deleted after <strong>60 days</strong> of inactivity. All associated personal information will be permanently removed.  </li>
<li>Accounts with <strong>surveys or responses</strong> will be retained for <strong>one year</strong> after the last interaction. After this period, the account and all associated data will be permanently deleted unless legal obligations require us to retain it for a longer duration.</li>
</ul>
<p>If your account is deleted automatically due to inactivity, all personal data and survey responses will be lost and cannot be recovered.</p>
<h4 id="-b-survey-responses-"><strong>b) Survey Responses</strong></h4>
<p><strong>Survey Data (Survey Owners)</strong>: Survey responses and data collected via the Sabasi platform are controlled by the <strong>Survey Owner</strong>. Sabasi retains the data on behalf of the Survey Owner for as long as instructed by the Owner. Survey Owners are responsible for setting retention and deletion policies for the survey data they collect, in compliance with relevant data protection regulations. Once a Survey Owner requests deletion or after the survey has been fulfilled, Sabasi will delete the data promptly.</p>
<p>Survey participants should contact the Survey Owner directly for inquiries about the retention and deletion of their survey responses.</p>
<h4 id="-c-communication-records-"><strong>c) Communication Records</strong></h4>
<p><strong>Support and Communication Records</strong>: Communications between you and Sabasi’s support team (such as emails or messages) are retained for <strong>up to two years</strong> after the issue is resolved. This retention period allows us to maintain a history of support interactions for troubleshooting and improving service quality. These records will be deleted after two years unless further retention is required by law.</p>
<h4 id="-d-backups-and-recovery-"><strong>d) Backups and Recovery</strong></h4>
<p>Sabasi performs regular backups of all user data, including survey responses and personal information, to a secure, remote location. Backups are kept for a rolling period of <strong>90 days</strong>. In the event of data loss or system failure, we use these backups to restore user data to the most recent available state. Once data is deleted from our systems, it may remain in backups for up to 90 days before being fully purged.</p>
<h4 id="-e-logs-and-monitoring-data-"><strong>e) Logs and Monitoring Data</strong></h4>
<p>For security and operational purposes, we retain system logs and monitoring data, such as server access records, for a period of <strong>up to 12 months</strong>. This information is used to detect, investigate, and prevent unauthorized access, breaches, and other security incidents. Logs may also be retained for compliance purposes, depending on regulatory requirements.</p>
<h4 id="-f-legal-obligations-"><strong>f) Legal Obligations</strong></h4>
<p>In some cases, we may be required to retain certain personal data for extended periods to comply with legal, tax, or regulatory obligations. For example, financial records or records related to legal disputes may need to be retained for a minimum of <strong>seven years</strong> under applicable laws.</p>
<h4 id="-g-deletion-and-anonymization-"><strong>g) Deletion and Anonymization</strong></h4>
<p>Once the retention period expires, or the data is no longer needed for its original purpose, we will securely delete or anonymize the data. Anonymization involves removing personal identifiers so that the data can no longer be linked to an individual. This allows us to use aggregated, non-personal data for statistical analysis, research, or platform improvement without compromising user privacy.</p>
<h4 id="-h-user-initiated-deletion-requests-"><strong>h) User-Initiated Deletion Requests</strong></h4>
<p>You have the right to request the deletion of your personal information at any time. To initiate a deletion request, please contact us at <a href="mailto:help@sabasi.mobi">help@sabasi.mobi</a>. We will process your request within <strong>30 days</strong>, unless specific circumstances require us to retain the data (e.g., legal or regulatory obligations). Once your request is processed, we will securely delete or anonymize your personal data.</p>
<h4 id="-i-data-retention-for-eu-eea-users-"><strong>i) Data Retention for EU/EEA Users</strong></h4>
<p>If you are located in the European Union (EU) or European Economic Area (EEA), we will retain your personal data in compliance with the General Data Protection Regulation (GDPR). You have the right to request that we erase your data at any time, subject to any legal obligations we may have to retain it. We will provide a clear explanation of any such obligations if they apply to your request.</p>
<h4 id="-j-survey-owner-responsibilities-for-data-retention-"><strong>j. Survey Owner Responsibilities for Data Retention</strong></h4>
<p>Survey Owners are responsible for managing the retention and deletion of the survey data they collect. Sabasi provides the platform to store and process this data, but Survey Owners must:</p>
<ul>
<li><strong>Define data retention periods</strong> for the surveys they create, ensuring that personal data is only retained for as long as necessary.  </li>
<li><strong>Comply with data protection laws</strong>, such as the GDPR and Kenyan Data Protection Act, regarding the retention and deletion of personal data.  </li>
<li><strong>Request data deletion</strong> from Sabasi when survey data is no longer needed or if a participant requests deletion of their personal data.</li>
</ul>
<p>Survey Owners must also inform survey participants of their data retention policies through their own privacy policies.</p>
<h4 id="-k-consistency-in-retention-periods-"><strong>k. Consistency in Retention Periods</strong></h4>
<p>The retention periods outlined above are designed to ensure consistency and compliance with applicable data protection regulations. Any variations in retention times are based on the nature of the data and the operational needs of Sabasi and Survey Owners. If you have any questions regarding data retention, please contact us at <a href="mailto:help@sabasi.mobi">help@sabasi.mobi</a> .</p>
<h3 id="-11-role-of-sabasi-as-data-processor-and-data-controller-"><strong>11. Role of Sabasi as Data Processor and Data Controller</strong></h3>
<p>Sabasi operates under two distinct roles depending on how data is collected and processed: <strong>data processor</strong> and <strong>data controller</strong>. This section outlines the responsibilities and obligations associated with each role, in compliance with international data protection regulations such as the <strong>General Data Protection Regulation (GDPR)</strong>.</p>
<h4 id="-sabasi-as-a-data-processor-"><strong>Sabasi as a Data Processor</strong></h4>
<p>When Sabasi processes data on behalf of an organization or individual (the &quot;Survey Owner&quot;) that is collecting information through our platform, Sabasi acts as a <strong>data processor</strong>. In this role, Sabasi only processes personal data according to the instructions and purposes defined by the Survey Owner. We do not control the data or determine how it is used; this is the responsibility of the Survey Owner, who acts as the <strong>data controller</strong> in this case.</p>
<p><strong>Key Responsibilities as a Data Processor:</strong></p>
<ul>
<li><strong>Following Instructions</strong>: We process data strictly as directed by the Survey Owner. This includes collecting, storing, and transmitting survey responses as specified by the controller.  </li>
<li><strong>Security and Confidentiality</strong>: We are responsible for implementing adequate security measures to protect the data we process. This includes encryption, access control, and secure storage, as outlined in our security policies.  </li>
<li><strong>Non-Disclosure</strong>: We do not access or use the data for any purposes other than those specified by the Survey Owner. We only disclose data to third parties if directed by the Survey Owner or if required by law.  </li>
<li><strong>Compliance with GDPR</strong>: If the Survey Owner is subject to the GDPR, we ensure that our data processing activities comply with the regulations. This includes signing <strong>Data Processing Agreements (DPA)</strong> with Survey Owners when required, to formalize our role as a data processor.</li>
</ul>
<p><strong>Example</strong>: If a non-profit organization creates a survey using the Sabasi platform, and participants provide their personal data through the survey, Sabasi acts as the data processor. The non-profit organization is the data controller and is responsible for determining how the data is used.</p>
<h4 id="-sabasi-as-a-data-controller-"><strong>Sabasi as a Data Controller</strong></h4>
<p>In cases where Sabasi collects and processes personal data directly from users (e.g., for account registration, account management, or communication), Sabasi acts as a <strong>data controller</strong>. In this role, Sabasi determines the purpose and means of processing personal data and is responsible for ensuring compliance with applicable data protection laws.</p>
<p><strong>Key Responsibilities as a Data Controller:</strong></p>
<ul>
<li><strong>Purpose of Data Collection</strong>: Sabasi determines why and how personal data is collected from users when they create accounts, interact with the platform, or use our services.  </li>
<li><strong>Transparency and Consent</strong>: We inform users about what data is collected, how it will be used, and their rights. We ensure that consent is obtained where required, such as for marketing communications or location-based services.  </li>
<li><strong>Data Subject Rights</strong>: As the controller, Sabasi is responsible for allowing users to exercise their rights, including access to personal data, correction of inaccuracies, data deletion, and the ability to withdraw consent at any time.  </li>
<li><strong>Accountability</strong>: Sabasi is accountable for the proper handling of personal data and ensuring compliance with privacy regulations, including responding to user requests regarding their personal data.</li>
</ul>
<p><strong>Example</strong>: When a user signs up for a Sabasi account to create and manage surveys, Sabasi collects personal information such as their name, email, and organization details. In this case, Sabasi is the data controller, responsible for managing this data in accordance with privacy regulations.</p>
<h4 id="-transition-between-roles-"><strong>Transition Between Roles</strong></h4>
<p>In some cases, Sabasi may act as both a data controller and data processor at different stages of data processing. For example:</p>
<ul>
<li><strong>Account Data</strong>: When a user signs up, we are the data controller for the account information.  </li>
<li><strong>Survey Data</strong>: When the same user creates a survey, we act as the data processor for the participants&#39; data collected through the survey on behalf of the user (Survey Owner).</li>
</ul>
<p>To ensure compliance, Sabasi implements strict internal controls and security measures to protect data in both roles, adhering to industry standards and legal requirements.</p>
<h4 id="-contacting-sabasi-about-our-roles-"><strong>Contacting Sabasi About Our Roles</strong></h4>
<p>If you have any questions about how Sabasi acts as a data processor or data controller, or if you need clarification on your rights under data protection laws, you can contact us at <a href="mailto:help@sabasi.mobi">help@sabasi.mobi</a> </p>
<p>We are committed to ensuring transparency and compliance with all applicable data protection laws and providing users with the information they need to understand their rights and how their data is managed.</p>
<h3 id="-12-user-rights-and-data-requests-"><strong>12. User Rights and Data Requests</strong></h3>
<p>Sabasi is committed to respecting the rights of both registered users and survey participants regarding their personal information. However, it is important to clarify the different roles Sabasi plays and the corresponding rights available to each group.</p>
<h4 id="-rights-of-registered-users-"><strong>Rights of Registered Users</strong></h4>
<p>As a registered user of the Sabasi platform (such as survey creators or administrators), you are entitled to exercise the following rights regarding the personal information you provide when creating and managing your account:</p>
<ul>
<li><strong>Right to Access</strong>: You have the right to request a copy of the personal information we hold about you. You can contact us at <a href="mailto:help@sabasi.mobi">help@sabasi.mobi</a> to obtain a summary of your personal data.  </li>
<li><strong>Right to Rectification</strong>: You can request corrections to any inaccuracies in your personal data or update your information directly through your account settings.  </li>
<li><strong>Right to Deletion (Right to be Forgotten)</strong>: You may request that we delete your personal data, including your account information, surveys, and responses, unless we are required to retain it for legal or regulatory reasons.  </li>
<li><strong>Right to Data Portability</strong>: You can request that your personal data be provided in a machine-readable format for transfer to another service provider.  </li>
<li><strong>Right to Restrict Processing</strong>: You may request that we limit the processing of your personal data in certain circumstances, such as while we are correcting or verifying information.  </li>
<li><strong>Right to Withdraw Consent</strong>: Where we rely on your consent to process personal data (e.g., for marketing purposes), you can withdraw that consent at any time.  </li>
<li><strong>Right to Object</strong>: You have the right to object to certain types of data processing, such as direct marketing or profiling.</li>
</ul>
<p>To exercise any of these rights, please contact us at <a href="mailto:help@sabasi.mobi">help@sabasi.mobi</a> . We will respond to your request within <strong>30 days</strong>, unless there are legal grounds for extending this period.</p>
<h4 id="-rights-of-survey-participants-"><strong>Rights of Survey Participants</strong></h4>
<p>If you are a survey participant providing data through the Sabasi platform, please note that Sabasi processes your data <strong>on behalf of the Survey Owner</strong> (the organization or individual who created the survey). In this case, the Survey Owner is the <strong>data controller</strong> responsible for managing your data and determining how it is used. As such, Sabasi is unable to directly fulfill requests related to your data rights.</p>
<p>If you are a survey participant and wish to:</p>
<ul>
<li><strong>Access, correct, or delete your data</strong>,  </li>
<li><strong>Withdraw consent for data processing</strong>,  </li>
<li><strong>Object to the processing of your data</strong>,</li>
</ul>
<p>You must <strong>contact the Survey Owner directly</strong>. They are responsible for handling such requests in accordance with their own privacy policies and applicable data protection laws. Sabasi does not have the authority to grant or deny access to your personal data submitted through surveys.</p>
<h4 id="-clarification-on-data-requests-"><strong>Clarification on Data Requests</strong></h4>
<p>Sabasi cannot independently modify or delete survey responses collected on behalf of a Survey Owner. However, we work closely with Survey Owners to ensure that they can manage your data requests effectively.</p>
<p>If you have difficulty contacting the Survey Owner or need assistance with your request, you may reach out to Sabasi’s support team at <a href="mailto:help@sabasi.mobi">help@sabasi.mobi</a> , and we will attempt to facilitate communication with the Survey Owner.</p>
<h3 id="-13-data-sharing-with-survey-owners-"><strong>13. Data Sharing with Survey Owners</strong></h3>
<p>When you participate in a survey using the Sabasi platform, your personal data and survey responses are shared with the <strong>Survey Owner</strong> (the organization or individual who created the survey). Sabasi acts as a <strong>data processor</strong> in these situations, processing the data on behalf of the Survey Owner, who is the <strong>data controller</strong>. It is important to understand the following:</p>
<h4 id="-1-responsibilities-of-survey-owners-"><strong>1. Responsibilities of Survey Owners</strong></h4>
<p>Survey Owners are solely responsible for determining how the data they collect through Sabasi is processed, used, and stored. This includes complying with all applicable data protection laws, such as the <strong>General Data Protection Regulation (GDPR)</strong> or other local regulations. Survey Owners are required to:</p>
<ul>
<li><strong>Provide their own Privacy Policy</strong>: Survey Owners must inform survey participants about how their data will be used, processed, and stored through their own privacy policies. This should include details about the purpose of data collection, retention periods, and how participants can exercise their rights.  </li>
<li><strong>Obtain consent</strong>: Survey Owners must ensure that they have obtained valid consent from survey participants, where required, before collecting any personal or sensitive data.  </li>
<li><strong>Ensure data security</strong>: Survey Owners are responsible for implementing appropriate security measures to protect the personal data they collect, including encryption, access control, and secure storage. They must ensure that the data is handled in accordance with the relevant legal standards.</li>
</ul>
<h4 id="-2-sabasi-s-role-"><strong>2. Sabasi’s Role</strong></h4>
<p>Sabasi provides the platform and tools for Survey Owners to collect data, but we do not control how Survey Owners use or process the data once it is collected. As a data processor, we follow the instructions of the Survey Owner and are not involved in the decisions regarding the purpose of data collection or how the data is managed.</p>
<ul>
<li><strong>No liability for misuse</strong>: Sabasi is not liable for any misuse, unauthorized access, or breach of personal data that occurs due to the actions or negligence of the Survey Owner. It is the responsibility of the Survey Owner to ensure that their use of the data complies with legal requirements.  </li>
<li><strong>Compliance with legal requests</strong>: If Sabasi receives a legal request related to the data we process on behalf of a Survey Owner (e.g., from law enforcement or regulatory authorities), we will notify the Survey Owner, unless prohibited by law, and will follow the legal processes required to handle such requests.</li>
</ul>
<h4 id="-3-requiring-compliance-from-survey-owners-"><strong>3. Requiring Compliance from Survey Owners</strong></h4>
<p>To use the Sabasi platform, Survey Owners must agree to:</p>
<ul>
<li><strong>Comply with all applicable data protection laws</strong> in the jurisdictions where they operate, including ensuring that they have the legal basis to collect, process, and store personal data.  </li>
<li><strong>Protect the rights of survey participants</strong>, including providing a mechanism for participants to exercise their rights (e.g., access, correction, deletion) as required by law.  </li>
<li><strong>Handle sensitive data with care</strong>: If Survey Owners are collecting sensitive personal data (such as health information, ethnicity, or other protected categories), they must comply with the additional legal protections required for such data.</li>
</ul>
<p>Survey participants should review the <strong>Privacy Policy of the Survey Owner</strong> to understand how their data will be used and managed. If you have any concerns about how a Survey Owner is handling your data, please contact them directly. Sabasi is not responsible for the data management practices of the Survey Owner.</p>
<h4 id="-4-assisting-with-data-requests-"><strong>4. Assisting with Data Requests</strong></h4>
<p>While Sabasi does not control the data collected through surveys, we work with Survey Owners to ensure that they can manage data requests effectively. If you, as a survey participant, wish to exercise your data rights (such as requesting access or deletion of your data), you must contact the Survey Owner. If you experience difficulties reaching the Survey Owner, you can contact Sabasi at <a href="mailto:help@sabasi.mobi">help@sabasi.mobi</a> , and we will facilitate communication with the Survey Owner.</p>
<h3 id="-data-breach-notification-"><strong>Data Breach Notification</strong></h3>
<p>Sabasi takes the security of your personal data seriously and has implemented measures to detect, prevent, and respond to data breaches. In the event of a data breach that compromises your personal data, we will take swift action to mitigate the impact and ensure compliance with legal requirements for data breach notifications.</p>
<h4 id="-1-internal-response-to-data-breaches-"><strong>1. Internal Response to Data Breaches</strong></h4>
<p>Upon detecting a potential data breach, Sabasi will:</p>
<ul>
<li><strong>Assess the breach</strong> to determine the nature, extent, and severity of the compromised data.  </li>
<li><strong>Contain and mitigate the breach</strong> to prevent further exposure of personal data.  </li>
<li><strong>Conduct a thorough investigation</strong> to understand the cause of the breach and implement corrective measures to prevent future incidents.</li>
</ul>
<h4 id="-2-notification-to-users-"><strong>2. Notification to Users</strong></h4>
<p>If a data breach involves personal data that poses a risk to your rights and freedoms, Sabasi will notify affected users without undue delay. This notification will include:</p>
<ul>
<li>A description of the nature of the breach, including the types of data involved.  </li>
<li>The potential consequences of the breach for affected users.  </li>
<li>The steps Sabasi has taken or is taking to address the breach and mitigate its impact.  </li>
<li>Recommendations for affected users to protect themselves (e.g., changing passwords or monitoring accounts).  </li>
<li>Contact information for further inquiries about the breach.</li>
</ul>
<p>In compliance with the <strong>General Data Protection Regulation (GDPR)</strong>, Sabasi will notify affected users <strong>within 72 hours</strong>of becoming aware of the breach, unless it can be demonstrated that the breach is unlikely to result in a risk to the rights and freedoms of individuals.</p>
<h4 id="-3-notification-to-authorities-"><strong>3. Notification to Authorities</strong></h4>
<p>In the event of a data breach, Sabasi will notify the appropriate data protection authorities, such as the <strong>Office of the Data Protection Commissioner</strong> in Kenya or the relevant supervisory authority in the European Union, if the breach involves personal data subject to the <strong>GDPR</strong>. This notification will be made within <strong>72 hours</strong> of discovering the breach, as required by law.</p>
<h4 id="-4-third-party-data-breach-notifications-"><strong>4. Third-Party Data Breach Notifications</strong></h4>
<p>If the data breach involves third-party service providers (e.g., cloud hosting providers) that Sabasi works with, we will coordinate with these providers to ensure a unified and timely response. Sabasi will ensure that users are notified promptly and that the breach is fully investigated and resolved in collaboration with the third-party provider.</p>
<h4 id="-5-preventative-measures-"><strong>5. Preventative Measures</strong></h4>
<p>Sabasi has implemented several security measures to prevent data breaches, including:</p>
<ul>
<li><strong>Encryption of personal data</strong> both at rest and in transit to protect against unauthorized access.  </li>
<li><strong>Access controls</strong> to ensure that only authorized personnel can access sensitive data.  </li>
<li><strong>Regular security audits and vulnerability assessments</strong> to identify and address potential risks.</li>
</ul>
<p>While Sabasi works diligently to prevent data breaches, we recognize that breaches may still occur, and we are prepared to respond promptly and transparently to protect the rights of our users.</p>
<p>If you have any questions or concerns about our data breach response plan, please contact us at <a href="mailto:help@sabasi.mobi">help@sabasi.mobi</a> </p>
<h3 id="-14-data-anonymisation-and-aggregation-"><strong>14. Data Anonymisation and Aggregation</strong></h3>
<p>Sabasi may anonymise and aggregate personal data for purposes such as statistical analysis, research, or platform improvement. The anonymisation process ensures that personal data is stripped of all identifying information, making it impossible to link the anonymised data back to any individual. Sabasi is committed to ensuring that the anonymisation process complies with the <strong>General Data Protection Regulation (GDPR)</strong> and other applicable data protection laws.</p>
<h4 id="-1-anonymisation-process-"><strong>1. Anonymisation Process</strong></h4>
<p>The anonymisation process involves the following steps to ensure that individuals cannot be re-identified:</p>
<ul>
<li><strong>Removal of Direct Identifiers</strong>: All personal identifiers, such as names, email addresses, and other direct identifiers, are removed from the dataset.  </li>
<li><strong>Pseudonymisation</strong>: In cases where data must be processed for further analysis before full anonymisation, Sabasi may use pseudonymisation techniques. Pseudonymised data replaces identifying information with pseudonyms, but can still be re-linked to the individual under certain conditions. However, full anonymisation will be applied before any data is used for external purposes.  </li>
<li><strong>Aggregation</strong>: Data is aggregated so that it can only be analyzed at a collective level, ensuring that individual contributions cannot be singled out or re-identified.  </li>
<li><strong>Additional Safeguards</strong>: Sabasi uses additional safeguards, such as data minimisation and randomisation techniques, to ensure that any patterns or combinations in the data do not allow for re-identification.</li>
</ul>
<h4 id="-2-compliance-with-gdpr-standards-"><strong>2. Compliance with GDPR Standards</strong></h4>
<p>The <strong>GDPR</strong> requires that anonymised data be processed in a way that makes it impossible to re-identify individuals. Sabasi complies with this requirement by:</p>
<ul>
<li><strong>Irreversible Anonymisation</strong>: Once data has been anonymised, it is not possible to reverse the process or re-identify the individuals whose data was collected.  </li>
<li><strong>Processing of Anonymised Data</strong>: Anonymised data is no longer considered personal data under the GDPR. As such, it is not subject to the same restrictions on data processing. However, Sabasi ensures that anonymised data is handled responsibly and used strictly for legitimate purposes, such as statistical analysis and research.</li>
</ul>
<h4 id="-3-use-of-anonymised-data-"><strong>3. Use of Anonymised Data</strong></h4>
<p>Anonymised data may be used for:</p>
<ul>
<li><strong>Statistical Analysis</strong>: Aggregated, anonymised data can be analyzed to gain insights into trends and patterns, without compromising the privacy of individuals.  </li>
<li><strong>Research and Development</strong>: Anonymised data helps Sabasi improve its platform and services by identifying usage patterns and areas for improvement.  </li>
<li><strong>Reporting and Publication</strong>: Sabasi may publish reports or share anonymised data with third parties for research or business purposes, provided that the data cannot be linked back to any individual.</li>
</ul>
<h4 id="-4-limitations-of-anonymisation-"><strong>4. Limitations of Anonymisation</strong></h4>
<p>While Sabasi takes all necessary steps to ensure full anonymisation, it is important to note that:</p>
<ul>
<li><strong>Re-identification Risk</strong>: In some rare cases, even anonymised data may present a risk of re-identification when combined with other external datasets. Sabasi takes every precaution to minimise this risk, including applying robust anonymisation techniques and carefully selecting the datasets used for analysis.  </li>
<li><strong>Data Minimisation</strong>: To further reduce the risk of re-identification, Sabasi ensures that only the minimum amount of data necessary for the intended purpose is collected and processed.</li>
</ul>
<h4 id="-5-your-rights-regarding-anonymised-data-"><strong>5. Your Rights Regarding Anonymised Data</strong></h4>
<p>Since anonymised data is no longer considered personal data under the GDPR, it is not subject to the same rights (such as access, correction, or deletion) as identifiable data. However, if you have any concerns about the anonymisation of your data, you may contact Sabasi at <a href="mailto:help@sabasi.mobi">help@sabasi.mobi</a>, and we will address your concerns to the best of our ability.</p>
<h3 id="-15-account-deletion-policy-for-sabasi-"><strong>15. Account Deletion Policy for Sabasi</strong></h3>
<p>At <strong>Sabasi</strong>, we prioritize your privacy and make it easy for you to request the deletion of your account and data. You can delete your account directly through the app or by using the form below. Please read the information carefully to understand how we handle your data during the deletion process.</p>
<h4 id="-steps-to-request-account-deletion-"><strong>Steps to Request Account Deletion</strong></h4>
<ol>
<li><strong>In-App Deletion</strong>: You can delete your account directly within the Sabasi app:  <ul>
<li>Go to your account settings.  </li>
<li>Select the <strong>Delete Account</strong> option.  </li>
<li>Confirm the deletion by following the on-screen instructions.  </li>
</ul>
</li>
<li>Once confirmed, this action will permanently delete your account and all associated data, including any surveys or responses you have created or submitted.  </li>
<li><strong>Request Account Deletion by Email or Form</strong>: Alternatively, you can request account deletion by contacting us via the form below or by emailing <a href="mailto:help@sabasi.mobi">help@sabasi.mobi</a> .  <ul>
<li><strong>Form Submission</strong>: Please fill out the account deletion form, and we will process your request within <strong>30 days</strong>.</li>
</ul>
</li>
</ol>
<hr>
<h3 id="-account-deletion-request-form-"><strong>Account Deletion Request Form</strong></h3>
<p>You can also fill in a form in our <a href="https://sabasi.mobi/contact">contact us page</a> below to request the deletion of your Sabasi account and associated data. Our team will process your request and notify you once the deletion is complete.</p>
<hr>
<h4 id="-what-happens-when-you-delete-your-account-"><strong>What Happens When You Delete Your Account</strong></h4>
<ul>
<li><strong>Deleted Data</strong>: Once you request account deletion, the following types of data will be permanently deleted:  <ul>
<li>Your account information (name, email, organization details).  </li>
<li>Any surveys you have created.  </li>
<li>All responses associated with your surveys or submitted as a participant.  </li>
<li>Any communication records associated with your account.  </li>
</ul>
</li>
<li><strong>Data Retention Before Deletion</strong>: Once you initiate account deletion, your data will be retained for up to <strong>30 days</strong> to allow for processing and ensure compliance with legal requirements. After this period, all data will be permanently removed from our systems.</li>
</ul>
<h4 id="-data-that-may-be-retained-"><strong>Data That May Be Retained</strong></h4>
<ul>
<li><strong>Backup Data</strong>: Backup copies of your data may be retained for up to <strong>90 days</strong> after account deletion for disaster recovery and legal compliance. These backups are encrypted and inaccessible unless required for system restoration.  </li>
<li><strong>Legal Retention</strong>: In some cases, we may be required to retain certain data for legal or regulatory reasons. If this applies to your account, we will inform you during the deletion process.</li>
</ul>
<p>For further details on our privacy practices, data retention, and account deletion, please refer to our full <strong>Privacy Policy</strong> <a href="https://sabasi.mobi/privacy-policy">https://sabasi.mobi/privacy-policy</a>.</p>
<h3 id="-16-changes-to-this-privacy-notice-"><strong>16. Changes to This Privacy Notice</strong></h3>
<p>We may update this Privacy Notice from time to time to reflect changes in our practices, technologies, legal requirements, or other factors. It is important that you review this Privacy Notice periodically to stay informed about how we are protecting your personal information.</p>
<h4 id="-a-notification-of-changes-"><strong>a) Notification of Changes</strong></h4>
<p>When we make significant changes to this Privacy Notice, we will notify you in a clear and timely manner. Notifications may be sent via:</p>
<ul>
<li><strong>Email</strong>: We will send a notification to the email address associated with your account, detailing the changes.  </li>
<li><strong>In-App Notification</strong>: You will see a prominent notification within the Sabasi platform, which will direct you to the updated Privacy Notice.  </li>
<li><strong>Website Update</strong>: We will post the updated Privacy Notice on our website and update the &quot;Effective Date&quot; at the top of the page to reflect when the changes go into effect.</li>
</ul>
<h4 id="-b-review-period-"><strong>b) Review Period</strong></h4>
<p>For major changes that impact how we handle your personal information, we will provide a <strong>review period</strong> of at least <strong>30 days</strong> before the changes take effect. During this period, you will have the opportunity to review the changes and decide whether you agree to the new terms. If you do not agree, you can choose to discontinue using the Sabasi platform and delete your account.</p>
<h4 id="-c-minor-changes-"><strong>c) Minor Changes</strong></h4>
<p>For minor updates, such as clarifications or improvements that do not significantly affect your rights or obligations, the changes will take effect immediately upon posting. We encourage you to regularly review the Privacy Notice for any updates.</p>
<h4 id="-d-continued-use-after-changes-"><strong>d) Continued Use After Changes</strong></h4>
<p>Your continued use of the Sabasi platform after any changes to this Privacy Notice constitutes your acceptance of those changes. If you disagree with any changes, you must stop using the platform and may request to delete your account as described in the <strong>Account Information</strong> section.</p>
<h4 id="-e-historical-versions-"><strong>e) Historical Versions</strong></h4>
<p>To maintain transparency, we will archive previous versions of this Privacy Notice and make them available upon request. You can contact us at <a href="mailto:help@sabasi.mobi">help@sabasi.mobi</a> to request a copy of an earlier version of this Privacy Notice.</p>
<h4 id="-f-user-responsibility-"><strong>f) User Responsibility</strong></h4>
<p>We strongly encourage you to keep your contact information, including your email address, up to date to ensure you receive any notices regarding updates to this Privacy Notice. It is your responsibility to review any changes that we communicate to you.</p>
<h3 id="-17-how-to-contact-sabasi-"><strong>17. How to Contact Sabasi</strong></h3>
<p>If you have any questions, concerns, or requests regarding this Privacy Notice, or if you need assistance with your account or the services provided by Sabasi, you can contact us through the following methods:</p>
<h4 id="-a-by-email-"><strong>a) By Email</strong></h4>
<p>For general inquiries, account issues, or requests related to your personal information, you can reach our support team at:</p>
<ul>
<li><strong>Email</strong>: <a href="mailto:help@sabasi.mobi">help@sabasi.mobi</a> </li>
</ul>
<p>We aim to respond to all inquiries within <strong>48 hours</strong>. For more complex requests, such as data deletion or access requests, we will provide an update within <strong>7 business days</strong> and complete the request within <strong>30 days</strong>, as required by applicable data protection laws.</p>
<h4 id="-b-by-postal-mail-"><strong>b) By Postal Mail</strong></h4>
<p>If you prefer to contact us by postal mail, you can send written correspondence to our office at the following address:</p>
<ul>
<li><strong>Sabasi Team (Open Institute)</strong><br>9 Riverside Drive,<br>Nairobi, Kenya</li>
</ul>
<p>Please include detailed information about your inquiry or request in your letter so we can assist you as efficiently as possible. Note that responses to postal mail inquiries may take longer.</p>
<h4 id="-c-in-app-support-"><strong>c) In-App Support</strong></h4>
<p>You can contact our support team directly through the Sabasi platform by using the in-app support feature powered by <strong>Smartsupp</strong>. Simply navigate to the <strong>Support</strong> section of the platform, where you can:</p>
<ul>
<li>Submit a support ticket,  </li>
<li>Chat with a live agent (when available), or  </li>
<li>Access our knowledge base for immediate answers to common questions.</li>
</ul>
<p>For in-app support, we typically respond within <strong>24 hours</strong> for standard inquiries, and within <strong>1-3 business days</strong> for more detailed or complex requests.</p>
<h4 id="-d-social-media-"><strong>d) Social Media</strong></h4>
<p>You can also follow and reach out to Sabasi via our official social media accounts. While these channels are primarily for updates and community interaction, we monitor them regularly for any inquiries and will direct you to the appropriate support channels.</p>
<ul>
<li><strong>Twitter</strong>: <a href="https://x.com/open_institute">https://x.com/open_institute</a>  </li>
<li><strong>LinkedIn</strong>: <a href="https://www.linkedin.com/company/2865525/admin/dashboard/">https://www.linkedin.com/company/2865525/admin/dashboard/</a>  </li>
<li><strong>Facebook</strong>: <a href="https://web.facebook.com/TheOpenInstitute?_rdc=1&amp;_rdr">https://web.facebook.com/TheOpenInstitute?_rdc=1&amp;_rdr</a></li>
</ul>
<p>For privacy-related inquiries, we recommend using email or in-app support to ensure your request is handled in compliance with privacy laws.</p>
<h4 id="-e-for-data-protection-inquiries-"><strong>e) For Data Protection Inquiries</strong></h4>
<p>If you have questions specifically regarding data protection, including GDPR compliance, data access, deletion requests, or security concerns, please include &quot;Data Protection Inquiry&quot; in the subject line of your email to ensure your request is directed to the appropriate team:</p>
<ul>
<li><strong>Data Protection Officer</strong>: <a href="mailto:hello@openinstitute.com">hello@openinstitute.com</a> </li>
</ul>
<p>We take privacy and data security seriously, and our team will handle your inquiry in accordance with applicable data protection regulations.</p>
<h4 id="-f-escalation-and-complaints-"><strong>f) Escalation and Complaints</strong></h4>
<p>If you feel that your privacy rights have not been adequately addressed by our support team or you have a complaint regarding how we handle your personal information, you can escalate your concern by emailing us at <a href="mailto:help@sabasi.mobi">help@sabasi.mobi</a> . We will investigate your complaint and respond within <strong>14 days</strong>.</p>
<p>For further escalation, you may have the right to lodge a complaint with a data protection authority or regulatory body in your jurisdiction. We encourage you to contact us first so that we can resolve the issue promptly.</p>


                    </div>
                </div>
        </section>    

    </div>
</template>

<script>
export default {
    name: 'Privacy',
    methods: {
        auth() {
            return localStorage.getItem(process.env.VUE_APP_NAME + "_token") != null;
        }
    }
}
</script>